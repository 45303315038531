import React, { useState } from "react";
import {
	Wrapper,
	Header,
	HeaderInfo,
	InfoWrapper,
	InfoInner,
	InfoWrapperClose,
} from "./styled";
import {
	CustomButton,
	InfoIcon,
	LegalesIndex,
} from "../../../assets/styles/commonStyles";
import ModeloAccordion from "../ModeloAccordion";

const ModeloDetalleSliderItem = (props) => {
	const {
		image,
		title,
		pvp,
		financiacion,
		button,
		featuresList,
		collapseSlidesFeatures,
		setCollapseSlidesFeatures,
		mostrarLegalesIndex,
		legalesIndex,
	} = props;
	const [infoAnimation, setInfoAnimation] = useState(false);

	return (
		<Wrapper>
			<Header>
				<figure>
					<img src={image} alt={title} />
				</figure>
				<HeaderInfo>
					<h3>{title}</h3>
					<p>
						PVP recomendado:{" "}
						<strong>
							{pvp}
							{mostrarLegalesIndex && (
								<LegalesIndex>({legalesIndex})</LegalesIndex>
							)}
						</strong>
					</p>
					<p>
						Promoción por financiar: <strong>{financiacion.price}</strong>
						{/* <InfoIcon onClick={() => setInfoAnimation(true)}>i</InfoIcon> */}
					</p>
					<InfoWrapper anim={infoAnimation}>
						<InfoWrapperClose onClick={() => setInfoAnimation(false)} />
						<InfoInner>
							<p
								dangerouslySetInnerHTML={{
									__html: financiacion.description,
								}}
							/>
						</InfoInner>
					</InfoWrapper>
				</HeaderInfo>
				<CustomButton
					layout="dark"
					href={button.url}
					target={button.nuevaVentana ? "_blank" : "_top"}
					data-btn_solicitaroferta={button.dataBtnSolicitarOferta}
					data-financiacion={button.dataFinanciacion}
					data-modeledition={button.dataModelEdition}
				>
					{button.text}
				</CustomButton>
			</Header>
			{featuresList && (
				<ModeloAccordion
					items={featuresList}
					collapseSlidesFeatures={collapseSlidesFeatures}
					setCollapseSlidesFeatures={setCollapseSlidesFeatures}
				/>
			)}
		</Wrapper>
	);
};

export default ModeloDetalleSliderItem;
