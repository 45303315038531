import React from "react";
import { Wrapper } from "./styled";

const ModelHeader = ({ title, description }) => {
	return (
		<Wrapper>
			<h2>{title}</h2>
			<p>{description}</p>
		</Wrapper>
	);
};

export default ModelHeader;
