import React, { useState } from "react";
import Slider from "react-slick";
import "../../../assets/css/slick/slick.min.css";
import { Wrapper } from "./styled";
import SliderArrow from "../SliderArrow";
import SliderDots from "../SliderDots";
import ModeloDetalleSliderItem from "../ModeloDetalleSliderItem";
import API from "../../../classes/api";
import {
	OfertaConsumo,
	OfertaLegal,
} from "../../../assets/styles/commonStyles";

const ModelosDetalleSlider = ({ ofertas, salesgroup }) => {
	const [collapseSlidesFeatures, setCollapseSlidesFeatures] = useState(false);
	const sliderSettings = {
		dots: true,
		arrows: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 4,
		infinite: false,
		nextArrow: <SliderArrow color="dark" position="next" outside={true} />,
		prevArrow: <SliderArrow color="dark" position="prev" outside={true} />,
		appendDots: (dots) => <SliderDots dots={dots} />,
		beforeChange: () => {
			setCollapseSlidesFeatures(true);
		},
		responsive: [
			{
				breakpoint: 1441,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				},
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
				},
			},
			{
				breakpoint: 560,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
				},
			},
		],
	};

	let legales = [];

	return (
		<Wrapper>
			{ofertas && (
				<>
					<Slider {...sliderSettings}>
						{ofertas.map((oferta, index) => {
							let mostrarLegalesIndex = false;
							let titulo = "";
							if (oferta.carline.salesgroup.trimlevel.titulo !== "") {
								titulo = oferta.carline.salesgroup.trimlevel.titulo;
							} else {
								titulo = oferta.carline.titulo;
							}
							if (oferta.legal) {
								legales.push({
									index: legales.length + 1,
									body: oferta.legal,
								});
								mostrarLegalesIndex = true;
							}
							return (
								<ModeloDetalleSliderItem
									key={index}
									image={
										oferta.carline?.salesgroup?.trimlevel?.imagenes
											?.tresCuartos?.l
									}
									title={titulo}
									pvp={`${API.formatPrecio(oferta.precio, false)}€`}
									mostrarLegalesIndex={mostrarLegalesIndex}
									legalesIndex={legales.length}
									financiacion={{
										price: `-${API.formatPrecio(
											oferta.descuento,
											false,
										)}€`,
										description: oferta.legalDescuento,
									}}
									button={{
										text: oferta.botones[0]?.etiqueta,
										url: oferta.botones[0]?.url,
										nuevaVentana: oferta.botones[0].nuevaVentana,
										dataBtnSolicitarOferta: "solicitar_oferta",
										dataFinanciacion: oferta.tipo,
										dataModelEdition:
											oferta.carline?.salesgroup?.trimlevel?.codigo,
									}}
									featuresList={API.buildFeaturesFromOferta(oferta)}
									collapseSlidesFeatures={collapseSlidesFeatures}
									setCollapseSlidesFeatures={setCollapseSlidesFeatures}
								/>
							);
						})}
					</Slider>
					{salesgroup.legalConsumos && (
						<div style={{ marginTop: "20px" }}>
							<OfertaConsumo
								dangerouslySetInnerHTML={{
									__html: salesgroup.legalConsumos,
								}}
							/>
						</div>
					)}
					{legales.map((legal, index) => {
						return (
							<OfertaLegal
								dangerouslySetInnerHTML={{
									__html: `(${index + 1})<br />${legal.body}`,
								}}
								key={index}
							/>
						);
					})}
				</>
			)}
		</Wrapper>
	);
};

export default ModelosDetalleSlider;
