import React, { useEffect, useState } from "react";
import ModeloAccordionItem from "../ModeloAccordionItem";

const ModeloAccordion = (props) => {
	const {
		items,
		collapseSlidesFeatures,
		setCollapseSlidesFeatures,
		setIsFeaturesOpen,
	} = props;

	const [itemOpen, setItemOpen] = useState(null);

	useEffect(() => {
		if (collapseSlidesFeatures) {
			setItemOpen(null);
			setCollapseSlidesFeatures(false);
		}
	}, [collapseSlidesFeatures, setCollapseSlidesFeatures]);

	return (
		items &&
		items.map((item, index) => (
			<ModeloAccordionItem
				key={index}
				item={item}
				itemIndex={index}
				itemOpen={itemOpen}
				setItemOpen={setItemOpen}
				setIsFeaturesOpen={setIsFeaturesOpen}
			/>
		))
	);
};

export default ModeloAccordion;
