import styled, { css } from "styled-components";
import colors from "../../../assets/styles/colors";
import { device } from "../../../assets/styles/size";

export const Wrapper = styled.div`
	padding-bottom: 2px;
`;
export const Header = styled.div`
	border: 1px solid ${colors.gray};
	padding: 30px 15px;
	margin-bottom: 18px;
	box-sizing: border-box;
	figure {
		margin: 0 10px 26px;

		img {
			width: 100%;
		}
	}

	a {
		width: 100%;
		padding: 20px;
		box-sizing: border-box;
	}
`;
export const HeaderInfo = styled.div`
	margin-bottom: 10px;
	padding-bottom: 20px;
	position: relative;
	h3 {
		font-family: "AudiType-Extended";
		font-weight: 400;
		font-size: 24px;
		line-height: 1.2;
		margin: 0 0 50px;
		@media ${device.mobileLL} {
			font-size: 21px;
		}
		@media ${device.tablet} {
			font-size: 24px;
		}
		@media ${device.laptopL} {
			font-size: 30px;
		}
	}

	h4 {
		color: ${colors.grayDark};
		font-family: "AudiType-Extended";
		font-weight: 400;
		font-size: 15px;
		line-height: 1.2;
		margin: 0 0 20px;
		@media ${device.laptopL} {
			font-size: 16px;
		}
	  strong{
		 color: ${colors.black};
	  }
	}

	p {
		color: ${colors.black};
		font-family: "AudiType-Extended";
		font-weight: 400;
		font-size: 15px;
		line-height: 1.2;
		margin: 0 0 8px;
		position: relative;
		padding-right: 26px;
		span {
			position: absolute;
			right: 0;
			top: 0;
		}
		@media ${device.laptopL} {
			font-size: 16px;
		}
	}
`;

export const InfoWrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	background-color: ${colors.grayLight};
	border: 1px solid ${colors.gray};
	padding: 0;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
	transition: opacity 0.3s ease, visibility 0.3s ease;
	${(props) =>
		props.anim
			? css`
					opacity: 1;
					visibility: visible;
			  `
			: css`
					opacity: 0;
					visibility: hidden;
			  `};

	p {
		display: block;
		font-family: "AudiType-Extended";
		font-weight: 400;
		font-size: 15px;
		line-height: 1.3;
		padding: 10px 0;
	}
`;

export const InfoInner = styled.div`
	height: 100%;
	padding: 0 18px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	overflow-x: hidden;
	overflow-y: scroll;
	box-sizing: border-box;
	&::-webkit-scrollbar {
		width: 6px;
	}

	&::-webkit-scrollbar-track {
		// box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background-color: rgba(0, 0, 0, 0.2);
	}
`;

export const InfoWrapperClose = styled.div`
	cursor: pointer;
	position: absolute;
	top: 10px;
	right: 2px;
	transform: translateY(-50%) translateX(-50%);
	width: 12px;
	height: 12px;

	&:before,
	&:after {
		content: "";
		display: block;
		position: absolute;
		top: 46%;
		width: 100%;
		height: 1px;
		background-color: ${colors.grayDark};
		transform-origin: center;
		transition: background-color 0.3s ease;
	}

	&:before {
		transform: rotate(45deg);
	}

	&:after {
		transform: rotate(-45deg);
	}
`;
