import React, { useEffect, useRef, useState } from "react";
import {
	ItemWrapper,
	Title,
	ListWrapper,
	VerMasBtn,
	CollpapseIcon,
} from "./styled";

const ModeloAccordionItem = (props) => {
	const { item, itemIndex, itemOpen, setItemOpen, setIsFeaturesOpen } = props;

	const qtyToShow = 5;
	const infoListQty = item.infoList.length;
	const listWrapperRef = useRef();
	const [listWrapperShow, setListWrapperShow] = useState(false);
	const [listWrapperHeight, setListWrapperHeight] = useState(0);
	const [liShowed, setLiShowed] = useState(5);
	const [animVerMasBtn, setAnimVerMasBtn] = useState(false);
	const [showVerMasBtn, setShowVerMasBtn] = useState(true);

	const calculateLiShowed = () => {
		let liShowedTemp;
		if (liShowed + qtyToShow < infoListQty) {
			liShowedTemp = liShowed + qtyToShow;
		} else {
			liShowedTemp = infoListQty;
			setShowVerMasBtn(false);
			setAnimVerMasBtn(true);
		}
		setLiShowed(liShowedTemp);
	};

	const toggleOpenClose = () => {
		setListWrapperShow(!listWrapperShow);
		updateHeight(44); // 44 por el padding top y padding bottom que en un principio no tiene
	};

	const updateHeight = (addHeight = 0) => {
		setListWrapperHeight(listWrapperRef.current.scrollHeight + addHeight);
	};

	useEffect(() => {
		if (listWrapperRef) {
			updateHeight();
		}
	}, [liShowed]);

	useEffect(() => {
		// RESETEAR CUANDO CAMBIA DE PAGINA EL SLIDER
		if (itemOpen !== itemIndex) {
			setListWrapperHeight(0);
			setListWrapperShow(false);
		}
	}, [itemOpen]);

	return (
		<ItemWrapper active={itemOpen === itemIndex}>
			<Title
				onClick={() => {
					toggleOpenClose();
					if (itemOpen !== itemIndex) {
						setItemOpen(itemIndex);
						if (setIsFeaturesOpen) setIsFeaturesOpen(true);
					} else {
						setItemOpen(null);
					}
				}}
			>
				<h5>{item.title}</h5>
				<CollpapseIcon active={itemOpen === itemIndex}>
					<span />
				</CollpapseIcon>
			</Title>

			<ListWrapper
				ref={listWrapperRef}
				show={listWrapperShow}
				height={listWrapperHeight}
				onTransitionEnd={() => {
					if (itemOpen === null) {
						if (setIsFeaturesOpen) setIsFeaturesOpen(false);
					}
				}}
			>
				<ul>
					{item.infoList
						.filter((listItem, index) => index < liShowed)
						.map((listItem, listItemIndex) => (
							<li key={listItemIndex}> {listItem.value}</li>
						))}
				</ul>
				{showVerMasBtn && (
					<VerMasBtn
						animVerMasBtn={animVerMasBtn}
						onClick={calculateLiShowed}
						// onTransitionEnd={() => {
						// 	setShowVerMasBtn(false);
						// 	updateHeight(-44);
						// 	// setTimeout(() => {
						// 	// 	updateHeight(-44);
						// 	// 	console.log("111111111111111111111");
						// 	// }, 10);
						// }}
					>
						Ver más <span />
					</VerMasBtn>
				)}
			</ListWrapper>
		</ItemWrapper>
	);
};

export default ModeloAccordionItem;
