import React, { useEffect, useState } from "react";
import { GlobalStyle } from "../assets/styles/globalStyles";
import {
	StyledSection,
	StyledSectionInner,
} from "../assets/styles/commonStyles";
import ModelHeader from "../components/common/ModelHeader";
import ModelosDetalleSlider from "../components/common/ModelosDetalleSlider";
import { connect } from "react-redux";
import API from "../classes/api";
import {
	getCarline,
	setSalesgroup,
	getEscalera,
} from "../redux/carlines/actions";
import ComponentLoading from "../components/common/ComponentLoading";
import Layout from "../components/common/Layout";
import PromocionModeloSlider from "../components/common/PromocionModeloSlider";
import { dataLayerPush } from "../classes/gtm";

const FinanciacionPage = ({
	location,
	carline,
	salesgroup,
	escalera,
	getCarline,
	setSalesgroup,
	getEscalera,
}) => {
	const [tipo, setTipo] = useState(null);
	const [carlineCodigo, setCarlineCodigo] = useState(null);
	const [salesgroupCodigo, setSalesgroupCodigo] = useState(null);

	useEffect(() => {
		// primero leer partes de la url
		if (location) {
			const pathParts = location.pathname.split("/");
			setTipo(pathParts[pathParts.length - 3]);
			setCarlineCodigo(pathParts[pathParts.length - 2]);
			setSalesgroupCodigo(pathParts[pathParts.length - 1]);
		}
	}, [location]);

	useEffect(() => {
		// hay tipo, carlineCodigo y salesgroupCodigo, cargar el carline de la api
		if (tipo && carlineCodigo && salesgroupCodigo) {
			getCarline({
				codigo: carlineCodigo,
				isEmpresa: false,
				ofertaTipo: "financiacion-lineal",
			});
		}
	}, [tipo, carlineCodigo, salesgroupCodigo]);

	useEffect(() => {
		// cuando hay carline, buscar el salesgroup en el carline
		if (carline) {
			setSalesgroup(
				API.findSalesgroupFromCarline(carline, salesgroupCodigo),
			);
		}
	}, [carline]);

	useEffect(() => {
		// cuando hay salesgroup, pedir escalera a la api
		if (salesgroup) {
			getEscalera(salesgroupCodigo);
		}
	}, [salesgroup]);

	// gtm

	useEffect(() => {
		if (carline && salesgroup && escalera) {
			const mejorOferta = API.findMejorOfertaFromEscalera(
				"financiacion-lineal",
				escalera,
			);
			dataLayerPush({
				seccion: "promociones",
				pagina: "financiacion-lineal",
				gama: carline.codigo,
				modelo: salesgroup.codigo,
				precio: mejorOferta ? mejorOferta.precio : "",
				utm_content: "",
				modelo_edicion: "",
				financiacion: "financiacion-lineal",
			});
		}
	}, [carline, salesgroup, escalera]);

	return (
		<Layout>
			<main>
				<GlobalStyle />

				{(!carline || !salesgroup || !escalera) && (
					<StyledSection>
						<StyledSectionInner noPaddingTopBot={true}>
							<ComponentLoading />
						</StyledSectionInner>
					</StyledSection>
				)}

				{carline && salesgroup && escalera && (
					<>
						<StyledSection>
							<StyledSectionInner noPaddingTopBot={true}>
								<PromocionModeloSlider
									position="left"
									sliderItems={(() => {
										const banners = API.findBanners(
											carline,
											salesgroup,
											"financiacion-lineal",
											false,
										);
										const items = banners.map((banner) => {
											return {
												id: banner.id,
												preTitle: `${
													salesgroup.nuevo ? "Nuevo" : ""
												} Audi ${carline.titulo} ${
													salesgroup.titulo
												}`,
												title: banner.trimlevel.titulo,
												price: banner.price
													? `${API.formatPrecio(banner.price)}€`
													: null,
												priceNote: "Con entrada. IVA incluído.",
												image: banner.imagenes?.secundaria?.l,
												featuresList: [
													{
														value: `Renting a 36 meses.`,
													},
													{
														value:
															"Todos los servicios incluidos.",
													},
													{
														value: "Equipamiento exclusivo.",
													},
												],
												buttons: (() => {
													if (banner.botones) {
														return banner.botones.map((boton) => {
															return {
																text: boton.etiqueta,
																url: boton.url,
																nuevaVentana:
																	boton.nuevaVentana,
															};
														});
													} else return null;
												})(),
											};
										});
										return items;
									})()}
								/>
							</StyledSectionInner>
						</StyledSection>

						{escalera.map((itemEscalera, index) => {
							return (
								<StyledSectionInner noPaddingBot={true} key={index}>
									<ModelHeader
										title={`${salesgroup.nuevo ? "Nuevo" : ""} Audi ${
											carline.titulo
										} ${salesgroup.titulo}`}
										description={`Motor: ${itemEscalera.titulo}`}
									/>
									<ModelosDetalleSlider
										ofertas={itemEscalera.ofertas.sort((a, b) => {
											return a.precio - b.precio;
										})}
										salesgroup={salesgroup}
									/>
								</StyledSectionInner>
							);
						})}
					</>
				)}
			</main>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const { carline, salesgroup, escalera } = state.carlines;
	return {
		carline,
		salesgroup,
		escalera,
	};
};

const mapDispatchToProps = {
	getCarline,
	setSalesgroup,
	getEscalera,
};

export default connect(mapStateToProps, mapDispatchToProps)(FinanciacionPage);
