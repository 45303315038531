import styled, { css } from "styled-components";
import colors from "../../../assets/styles/colors";

export const ItemWrapper = styled.div`
	border: 1px solid ${colors.gray};
	font-size: 15px;
	line-height: 1.2;
	margin-bottom: 10px;

	&:last-of-type {
		margin-bottom: 0;
	}

	h5 {
		font-weight: ${(props) => (props.active ? "700" : "400")};
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		box-sizing: border-box;

		li {
			margin-bottom: 20px;

			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
`;

export const ListWrapper = styled.div`
	position: relative;
	transition: height 0.3s ease, padding 0.3s ease;
	overflow: hidden;
	box-sizing: border-box;
	${(props) =>
		props.show
			? css`
					padding: 18px 22px 28px;
					height: ${props.height === "auto"
						? props.height
						: props.height + "px"};
			  `
			: css`
					padding: 0 22px;
					height: 0;
			  `};
	&:before {
		content: "";
		display: block;
		position: absolute;
		left: 18px;
		top: 0;
		width: 100%;
		max-width: 88%;
		height: 1px;
		background-color: ${colors.gray};
	}
	ul {
	}
`;

export const VerMasBtn = styled.a`
	cursor: pointer;
	flex-wrap: wrap;
	align-items: center;
	display: inline-flex;
	transition: all 0.15s ease;
	${(props) =>
		props.animVerMasBtn
			? css`
					opacity: 0;
					visibility: hidden;
					height: 0;
					margin-top: 0;
			  `
			: css`
					opacity: 1;
					visibility: visible;
					height: auto;
					margin-top: 30px;
			  `};

	p {
		margin: 0;
	}

	span {
		margin-left: 18px;
		margin-top: -4px;
		display: block;
		border-style: solid;
		border-color: ${colors.black};
		border-width: 0 1px 1px 0;
		padding: 6px;
		transform: rotate(45deg);
		width: 0;
		height: 0;
	}
`;

export const Title = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 18px 22px;
	cursor: pointer;

	h5 {
		font-size: inherit;
		line-height: inherit;
		width: calc(100% - 30px);
		margin: 0;
	}
`;

export const CollpapseIcon = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	overflow: hidden;
	background-color: ${colors.black};
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	span {
		width: 10px;
		height: 10px;
		position: relative;
		transition: transform 0.3s ease;
		transform: ${(props) => (props.active ? "rotate(90deg)" : "rotate(0)")};

		&:before,
		&:after {
			content: "";
			display: block;
			position: absolute;
			background-color: ${colors.white};
		}

		&:before {
			width: 100%;
			height: 1px;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			transition: opacity 0.3s ease;
			opacity: ${(props) => (props.active ? 0 : 1)};
		}

		&:after {
			height: 100%;
			width: 1px;
			left: 50%;
			top: 0;
			transform: translateX(-50%);
		}
	}
`;
