import styled from "styled-components";
import { device } from "../../../assets/styles/size";
import { SliderWrapperCommon } from "../../../assets/styles/commonStyles";

export const Wrapper = styled.div`
	${SliderWrapperCommon};
	.slick-slider {
	}
	.slick-list {
		margin-bottom: 0;
		@media ${device.laptopS} {
			margin-bottom: 40px;
		}
	}

	.slick-track {
	}
	.slick-slide {
		> div {
			height: auto !important;
		}
	}
`;
