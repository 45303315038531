import styled from "styled-components";
import colors from "../../../assets/styles/colors";
import { device } from "../../../assets/styles/size";

export const Wrapper = styled.div`
	color: ${colors.black};
	overflow: hidden;
	padding: 30px;
	box-sizing: border-box;
	margin: 0 auto 40px;
	background-color: ${colors.grayLight};
	h2 {
		font-family: "AudiType-Extended";
		font-weight: 700;
		font-size: 28px;
		line-height: 1.2;
		margin: 0 0 20px -2px;
	}
	p {
		font-family: "AudiType-Extended";
		font-size: 14px;
		line-height: 1;
		margin-top: 0;
		margin-bottom: 0;
	}
	@media ${device.laptopS} {
		h2 {
			font-size: 36px;
		}
		p {
			font-size: 18px;
		}
	}
	@media ${device.laptop} {
		padding: 40px;
		height: 240px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		justify-content: center;
	}

	@media ${device.laptopL} {
		h2 {
			font-size: 50px;
		}
		p {
			font-size: 20px;
		}
	}
`;
